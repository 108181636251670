import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import {
  StickySiderBarSection,
  RowWrapper,
  StickySideBarLeft,
  StickySideBarLeftSection,
  StickySideBarRight,
  FullWidthRow,
  ColumnBox,
} from "../../../components/Layout/styled"

import Footer from "../../../components/Layout/Footer"
import ProductSideBar from "../../../components/ProductSideBar"
import SeoComp from "../../../components/SeoComp"
import { pageViewEventScript } from "../../../utils/additionalScriptHelpers"

const QuotidienPage = ({ intl, data }) => {
  useEffect(() => {
    data.allDirectusSeoTranslation.nodes[0] &&
      data.allDirectusSeoTranslation.nodes[0].title &&
      pageViewEventScript(
        data.allDirectusSeoTranslation.nodes[0].title,
        intl.locale
      )
  }, [])
  const blockCounter = data => {
    let blocks = []
    let j = 1
    let firstcol = ""
    let secondcol = ""
    for (let i = 0; i < data.length; i = i + 2) {
      if (j % 2 == 0) {
        firstcol = "ColumnBox8"
        secondcol = "ColumnBox4"
      } else {
        firstcol = "ColumnBox4"
        secondcol = "ColumnBox8"
      }
      blocks.push(
        <RowWrapper align="flex-start" key={i.toString()}>
          {data[i] && (
            <ColumnBox className={firstcol}>
              <ImageWrapper>
                <ImageBox background={`url(${data[i].image})`}></ImageBox>
                <OverlayBox className="overlay_box">
                  <BoxTitle>{data[i].title}</BoxTitle>
                  <TextBox
                    dangerouslySetInnerHTML={{
                      __html: data[i].text,
                    }}
                  />
                  <ReadArticle
                    href={
                      ([undefined, "", "general"].includes(
                        process.env.GATSBY_ACTIVE_ENV
                      )
                        ? ""
                        : "/" + process.env.GATSBY_CURRENCY_TYPE) + data[i].link
                    }
                    target="_blank"
                  >
                    {"Lire l'article"}
                  </ReadArticle>
                </OverlayBox>
              </ImageWrapper>
            </ColumnBox>
          )}
          {data[i + 1] && (
            <ColumnBox className={secondcol}>
              <ImageWrapper>
                <ImageBox background={`url(${data[i + 1].image})`}></ImageBox>
                <OverlayBox className="overlay_box">
                  <BoxTitle>{data[i + 1].title}</BoxTitle>
                  <TextBox
                    dangerouslySetInnerHTML={{
                      __html: data[i + 1].text,
                    }}
                  />
                  <ReadArticle
                    href={
                      ([undefined, "", "general"].includes(
                        process.env.GATSBY_ACTIVE_ENV
                      )
                        ? ""
                        : "/" + process.env.GATSBY_CURRENCY_TYPE) +
                      data[i + 1].link
                    }
                    target="_blank"
                  >
                    {"Lire l'article"}
                  </ReadArticle>
                </OverlayBox>
              </ImageWrapper>
            </ColumnBox>
          )}
        </RowWrapper>
      )
      j++
    }
    return blocks
  }
  return (
    data &&
    data.allDirectusVosMauxDeDosTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: `les-maux-de-dos/les-maux-de-dos-au-quotidien`,
            en: "",
            de: "",
            es: "",
          }}
        />
        <StickySiderBarSection direction="row" align="flex-start">
          <StickySideBarLeftSection>
            <FullWidthRow direction="column">
              <RowWrapper>
                <Title>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusVosMauxDeDosTranslation.nodes[0]
                          .quotidien_page_content.title,
                    }}
                  />
                </Title>
              </RowWrapper>
              <FullWidthRow>
                <RowWrapper direction="column" align="flex-start">
                  {blockCounter(
                    data.allDirectusVosMauxDeDosTranslation.nodes[0]
                      .quotidien_page_content.list
                  ).map(item => item)}
                </RowWrapper>
              </FullWidthRow>
            </FullWidthRow>
          </StickySideBarLeftSection>
          <StickySideBarRight>
            <FullWidthRow>
              <RowWrapper direction="column" align="center">
                <ProductSideBar
                  data={
                    data.allDirectusVosMauxDeDosTranslation.nodes[0]
                      .product_sidebar_content
                  }
                />
              </RowWrapper>
            </FullWidthRow>
          </StickySideBarRight>
        </StickySiderBarSection>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const Title = styled.h1`
  color: #262626;
  text-align: center;
  position: relative;
  font-family: "GothamLight";
  font-size: 1em;
  line-height: 1.4em;
  width: 100%;
  font-weight: normal;
  margin: 25px 0px;

  > span {
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    font-size: 150%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 0.3em 0.8em;

    @media (max-width: 550px) {
      font-size: 100%;
    }
  }

  :after {
    display: block;
    content: "";
    border-top: 2px solid #eaeaea;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
  }
`

const SubTitle = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  > p {
    margin: 0px 0px 15px 0px;
    font-weight: bold;
  }
`

const TextBox = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  margin: 10px 0px;

  > p {
    margin: 0px 0px 15px 0px;
  }
`

const ImageWrapper = styled.div`
  color: #262626;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const ImageBox = styled.div`
  background: ${props => props.background || props.theme.colors.white.base};
  color: #262626;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 420px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 0%;
`

const OverlayBox = styled.div`
  color: #262626;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  padding: 10px;
  bottom: 5%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.82);
  max-width: 350px;

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

const BoxTitle = styled.h2`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: "GothamLight";
  color: #262626;
`

const ReadArticle = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  font-size: 8px;
  font-family: "GothamLight";
  text-transform: uppercase;
  color: #666;
  font-weight: bold;

  &:before {
    content: "";
    height: 2px;
    background-color: #666;
    opacity: 0.3;
    transition: all 0.3s;
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 60%;
  }

  &:hover {
    color: #333;

    &:before {
      background-color: #333;
      left: 0%;
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    font-size: 10px;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusVosMauxDeDosTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        quotidien_page_content {
          title
          read_article_text
          list {
            image
            title
            text
            link
          }
        }
        product_sidebar_content {
          title
          subtitle
          mobile_button_text
          homme_text
          femme_text
          list {
            image
            link
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "les_maux_de_dos_au_quotidien" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(QuotidienPage)
